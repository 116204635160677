
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}


html {
  scroll-behavior: smooth;
}
