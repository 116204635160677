.testimonials {
  padding: 50px;
  background: url('../assets/Alienware-Dark-Logo-Wallpaper-HD.jpg') center/cover no-repeat;
}




.testimonial-item {
  margin-bottom: 20px;
}

.testimonial-item p {
  font-style: italic;
}

.testimonial-item h3 {
  font-size: 1.5rem;
  color: #333;
}

.testimonials h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: white;
}

.col-sm-8 {
  color: white;
}
.testimonials h2 {
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: white;
}

#quote-carousel {
  padding: 0 10px 30px 10px;
  margin-top: 60px;
}

#quote-carousel .carousel-control {
  background: none;
  color: black !important; /* Force the arrow color to black */
  font-size: 3em; /* Increase font size of arrows */
  text-shadow: none;
  margin-top: -30px; /* Adjust as necessary */
}

#quote-carousel .carousel-control .fa {
  color: black !important; /* Ensures Font Awesome icons are black */
}

#quote-carousel .carousel-inner {
  padding: 30px; /* Adds space around the text */
}

.carousel-indicators {
  position: relative;
  right: 50%;
  bottom: 0px;
  margin-top: 20px;
  margin-right: -19px;
}

.carousel-indicators li {
  width: 50px;
  height: 50px; 
  cursor: pointer;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%; 
  overflow: hidden; 
  transition: all .4s ease-in;
  vertical-align: middle;
}

.carousel-indicators li img {
  width: 100%;       /* Ensures image fits the container */
  height: 100%;      /* Ensures image fits the container */
  object-fit: cover; /* Ensures image covers the circle */
  border-radius: 50%; /* Makes sure image is circular */
}

.carousel-indicators .active {
  width: 60px; /* Adjust size as necessary */
  height: 60px; /* Adjust size as necessary */
  opacity: 1;
  transition: all .2s;
}

.item blockquote {
  border-left: none;
  margin: 0;
}

.item blockquote p:before {
  content: "\f10d";
  font-family: 'Fontawesome';
  float: left;
  margin-right: 10px;
}
