.services {
  padding: 60px 20px;
  text-align: center;
  background: url('../assets/plain-01.jpg') center/cover no-repeat;
}

.service-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: -20px;
}

.service-card {
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.black-line  {
  filter: brightness(0) invert(1);
  margin-top: -35px;
}

.service-card:hover {
  transform: translateY(-10px);
}



.services-card p {
  font-size: 1em;
  color: #e9e1e1;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}


.service-card h3 {
  font-size: 2.5em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 1em;
  color: #e9e1e1;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.services-include {
  text-align: center;
  margin-top: 20px;
}

.service-title {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.services-include h1 {
  font-size: 2em;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
  color: #ff0000;
}

.service-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

}

.service-details .service-card h3 {
  font-size: 1.2em;
  color: #ff0000;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-details .service-card p {
  font-size: 0.9em;
  color: #0f0606;
  line-height: 1.4;
  margin-top: 5px;
  cursor: pointer;
}

.card-description {
  color: white !important;
}

.highlighted-number {
  color: red !important;
}


/* Media query for mobile responsiveness */
@media only screen and (max-width: 768px) {
  .service-cards {
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }

  .service-card {
    width: 90%;
    margin-bottom: 20px;
  }

  .services-include h1 {
    font-size: 1.5em;
  }
}
