@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer {
  background: url('../assets/plain-01.jpg') center/cover no-repeat;
  color: #fff;
  padding: 3rem 0;
  text-align: center;
}

.footer-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.footer-grid h1 {
  font-size: 2rem;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.social-logo {
  display: flex;
  gap: 15px;
  margin-top: 1rem;
}

.social-logo a {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

.footer-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: left;
  padding: 0 20px;
}

.footer-column p {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.footer-item {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #ccc;
  cursor: pointer;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400 !important;
  font-style: normal;
}

.footer-item:hover {
  color: #fff;
}

.footer-bottom-bar {
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 2rem;
}

.footer-bottom-bar p {
  font-size: 1rem;
  color: #ccc;
}
