.certification{
  padding: 50px;
  background: url('../assets/plain-01.jpg') center/cover no-repeat;
}



.certification h1 {
font-size: 3em;
  color: #e9e1e1;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.certification p {
  color: #e9e1e1;
  font-family: "Dosis", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;

}

.certification-logos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 16px;
  margin-top: 20px;
  align-items: center;
}

.certification-logos img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-left: 205px;
}
