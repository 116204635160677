.hero {
  height: 100vh;
  background: url('../assets/best-background-for-website-14.jpg') center/cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}


.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.hero h1 {
  color: #FF0000;
  text-align: left;
  font-family: "Lobster Two", sans-serif;
  font-weight: bold 700;
  font-style: italic;
}

.hero h2 {
  color: #FFFFFF;
  text-align: left;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: bold 700;
  font-style: normal;
}

.hero p {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 40px;
  font-family: "Chokokutai", system-ui;
  font-weight: 400;
  font-style: normal;
}

.dynamics-text {
  font-family: "Dosis", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.hero-description {
  font-size: 13px !important; 
  color: #FFFFFF;
  text-align: left;
  font-family: "Dosis", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
  line-height: 1.6;
  margin-bottom: 20px;
}


.dynamic-text {
  font-weight: bold;
  font-size: 1.5rem;
  animation: fadeIn 1s ease-in-out;
  color: #FF0000;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.cta-button {
  padding: 10px 20px;
  background-color: #ff0000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-family: "Dosis", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.cta-button:hover {
  background-color: white;
  color : #FF0000
}

@media only screen and (max-width: 768px) {
  .hero {
    height: 70vh;
  }
}
