.contact {
  padding: 50px;
  background: url('../assets/best-background-for-website-14.jpg') center/cover no-repeat;
  color: white;
}

.contact-form {
  max-width: 600px;
  margin: auto;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.success-message, .error-message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.success-message {
  background-color: #28a745; 
  color: white;
}

.error-message {
  background-color: #dc3545;
  color: white;
}